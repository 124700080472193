<template>
  <div
    class="create-personal-list-panel"
    :class="{'phone': mq.phone}"
    @keydown.exact.enter.stop="create"
  >
    <div class="panel-content">
      <div class="title">
        <h3>{{ mode === 'create' ? $t('router.views.Contacts.addPersonalList') : $t('router.views.Contacts.updatePersonalList') }}</h3>

        <div
          class="icon-container"
          @click="closePanel"
        >
          <BaseIcon
            class="close-panel"
            name="times"
          />
        </div>
      </div>

      <div class="personal-list-name">
        <label>{{ $t('router.views.Contacts.personalListName') }}*</label>
        <Input
          id="name-input"
          v-model="createPersonalListName"
          :placeholder="$t('router.views.Contacts.personalListNamePlaceholder')"
          @input="clearError"
        />
        <div class="error-message">
          {{ error }}
        </div>
      </div>

      <div
        v-if="mq.phone"
        class="open-user-selection-button"
        @click="openUserSelection"
      >
        <!--        <div class="member-count">-->
        <!--          {{ members.length + ' ' + $t('router.views.Communities.member') + (members.length > 1 ? 's' : '') }}-->
        <!--        </div>-->
        <div class="add-icon">
          <div class="user-circle">
            <img
              src="@assets/icon_membre.svg"
              alt=""
              class="user-icon"
            >
            <img
              src="@assets/icon_add_element.svg"
              alt=""
              class="plus-icon"
            >
          </div>
        </div>
        <div
          class="label"
        >
          {{ mode === 'create' ? $t('router.views.Communities.addMembersLabel') : $t('router.views.Communities.selectUsers') + ' (' + members.length + ')' }}
        </div>
      </div>

      <Button
        v-if="mode === 'create'"
        class="submit-button dark"
        :label="$t('router.views.Contacts.create')"
        @click="create"
      />
      <Button
        v-else
        class="submit-button dark"
        :label="$t('router.views.Contacts.update')"
        @click="updatePersonalList"
      />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@components/Base/BaseIcon'
import contacts from '@/api/contacts/contacts.service'

export default {
  name: 'CreatePersonalListPanel',
  components: { BaseIcon },
  inject: ['mq'],
  props: {
    members: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      validator: (val) => ['create', 'update'].includes(val),
      required: true
    }
  },
  emits: ['openUserSelection', 'closePanel'],
  data () {
    return {
      createPersonalListName: '',
      error: ''
    }
  },
  computed: {
    selectedPersonalList () {
      return this.$store.state.contact.selectedList
    },
    memberIds () {
      return this.members.map(member => member.id)
    }
  },
  created () {
    if (this.mode === 'update') {
      this.createPersonalListName = this.selectedPersonalList.name
    }
  },
  mounted () {
    if (this.mode === 'create') {
      const input = document.getElementById('name-input')
      input.focus()
      input.select()
    }
  },
  methods: {
    create () {
      // Check fields
      if (this.createPersonalListName === '') {
        this.error = this.$t('router.views.Contacts.Error.emptyName')
      } else {
        if (this.error === '') {
          contacts.createPersonalList(this.createPersonalListName, this.memberIds).then((data) => {
            if (data.success) {
              this.$store.dispatch('contact/getUserPersonalLists', { idPersonalListToSelect: data.personalList.id, router: this.$router })
              this.closePanel()
            } else {
              console.error('Error while creating personal list', data.error)
            }
          })
        }
      }
    },
    updatePersonalList () {
      contacts.updatePersonalList(this.selectedPersonalList, this.createPersonalListName, this.memberIds).then((data) => {
        if (data.success) {
          this.$store.dispatch('contact/getUserPersonalLists', { idPersonalListToSelect: data.personalList.id, router: this.$router })
          this.closePanel()
        } else {
          console.error('Error while updating personal list', data.error)
        }
      })
    },
    clearError () {
      this.error = ''
    },
    openUserSelection () {
      this.$emit('openUserSelection')
    },
    closePanel () {
      this.$emit('closePanel')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.create-personal-list-panel {
  flex: 1;
  border-right: 1px solid #D9E2EA;
  padding-left: 5px;
  padding-right: 5px;

  .panel-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    .title {
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
    }

    .icon-container {
      cursor: pointer;
      margin-top: 10px;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #D9E2EA;
      border-radius: 20px;

      .close-panel {
        color: $color-light-text;
        font-size: 18px;
      }
    }

    .manager-profile {
      background-color: $dark-white-bg;
      margin-bottom: 10px;
      padding: 5px;
    }

    label {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.9rem;
    }

    .personal-list-name {
      margin-top: 20px;
    }

    .error-message {
      margin-top: 3px;
      color: red;
      font-size: 13px;
    }

    .community-description {
      margin-top: 20px;

      .text-area {
        resize: none;
        width: 100%;
        min-height: 100px;
      }
    }

    .member-count {
      margin: 20px 0;
      font-weight: bold;
    }

    .open-user-selection-button {
      width: 100%;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      .user-circle {
        position: relative;
        width: 47px;
        height: 47px;
        border-radius: 24px;
        background-color: $dark-white-bg;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        .plus-icon {
          position: absolute;
          top: -4px;
          right: -12px;
          width: 25px;
        }
      }
    }

    .submit-button {
      margin-top: auto;
      margin-bottom: 25px;
      height: 48px;

      -ms-user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none; /* CSS3 (little to no support) */
    }
  }

  &.phone {
    border-right: none;
  }
}
</style>
