<template>
  <AuthenticatedLayout>
    <div
      class="contacts theme-text-color"
      :class="{'phone': mq.phone, 'tablet': mq.tablet}"
    >
      <!-- Right panel -->
      <ContactListPanel
        v-if="mode === 'display'"
        @changeMode="changeMode"
      />
      <CreatePersonalListPanel
        v-else-if="(mode === 'update' && selectedListIsPersonalList) || mode === 'create'"
        :mode="mode"
        :members="createPersonalListMembers"
        @closePanel="changeMode('display')"
        @openUserSelection="isSelectUser = true"
      />

      <!-- Right panel -->
      <ContactDisplay
        v-if="selectedList !== undefined && mode === 'display'"
        class="right-panel"
        @displayPersonalListOptions="displayListOptions"
      />
      <UsersSelection
        v-else-if="selectedList !== undefined && mode === 'update' || mode === 'create'"
        v-show="(!mq.phone) || isSelectUser"
        v-model="createPersonalListMembers"
        :selected-community="selectedList"
        :select-creator="false"
        :mode="mode"
        :title="true"
        class="right-panel user-selection"
        @closeUserSelection="isSelectUser = false"
      />
      <ContactsPlaceHolder
        v-else-if="!mq.phone && !mq.tablet"
        :label="$t('router.views.Contacts.contactPlaceholder')"
        class="right-panel"
      />
    </div>

    <ContextMenu
      v-if="isAContextMenuDisplayed && isContextMenuDisplayed"
      @chooseOption="performChosenOption"
      @close="isContextMenuDisplayed=false"
    />

    <teleport to="body">
      <CreateMessageModal
        v-if="isCreateMessageModalDisplayed"
      />
    </teleport>
  </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@layouts/AuthenticatedLayout'
import contacts from '@/api/contacts/contacts.service'
import ContextMenu from '@components/ContextMenu/ContextMenu'
import utils from '@utils/utils'
import contextMenus from '@utils/contextMenus'
import UsersSelection from '@components/Communities/UsersSelection'
import CreatePersonalListPanel from '@components/Contacts/CreatePersonalListPanel'
import ContactsPlaceHolder from '@components/Contacts/ContactsPlaceHolder'
import ContactDisplay from '@components/Contacts/ContactDisplay'
import ContactListPanel from '@components/Contacts/ContactListPanel'
import CreateMessageModal from '@components/Messaging/CreateMessageModal'

export default {
  name: 'Contacts',
  components: { CreateMessageModal, ContactListPanel, ContactDisplay, ContactsPlaceHolder, CreatePersonalListPanel, UsersSelection, ContextMenu, AuthenticatedLayout },
  inject: ['mq'],
  data () {
    return {
      mode: 'display',
      createPersonalListName: '',
      createPersonalListMembers: [],
      isSelectUser: false,
      isContextMenuDisplayed: false
    }
  },
  computed: {
    userCommunities () {
      return this.$store.state.contact.userCommunities
    },
    userPersonalLists () {
      return this.$store.state.contact.userPersonalLists
    },
    selectedList () {
      return this.$store.state.contact.selectedList
    },
    selectedListMemberLabel () {
      return this.selectedList.memberCount > 1 ? this.$t('router.views.Communities.members') : this.$t('router.views.Communities.member')
    },
    isCreateMessageModalDisplayed () {
      return this.$store.state.messaging.isCreateMessageModalDisplayed
    },
    isAContextMenuDisplayed () {
      return this.$store.state.contextMenu.isAContextMenuDisplayed
    },
    selectedListIsCommunity () {
      if (this.selectedList) {
        return this.$store.getters['contact/communityListIndex'](this.selectedList.id) !== -1
      } else {
        return false
      }
    },
    selectedListIsPersonalList () {
      if (this.selectedList) {
        return this.$store.getters['contact/personalListIndex'](this.selectedList.id) !== -1
      } else {
        return false
      }
    },
    sortedUserCommunities () {
      const communities = []
      if (this.listSearchInput === '') {
        return this.userCommunities
      } else {
        for (let i = 0; i < this.userCommunities.length; ++i) {
          if (this.userCommunities[i].name.toLowerCase().search(this.listSearchInput.toLowerCase()) !== -1) {
            communities.push(this.userCommunities[i])
          }
        }
        return communities
      }
    },
    sortedUserPersonalLists () {
      const personalLists = []
      if (this.listSearchInput === '') {
        return this.userPersonalLists
      } else {
        for (let i = 0; i < this.userPersonalLists.length; ++i) {
          if (this.userPersonalLists[i].name.toLowerCase().search(this.listSearchInput.toLowerCase()) !== -1) {
            personalLists.push(this.userPersonalLists[i])
          }
        }
        return personalLists
      }
    },
    contextMenuOptions () {
      return utils.cloneArrayOfObject(contextMenus.personalListOptions)
    }
  },
  created () {
    this.$store.dispatch('contact/getUserCommunities')
    this.$store.dispatch('contact/getUserPersonalLists', { idPersonalListToSelect: this.$route.params.listId, mq: this.mq, router: this.$router })
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.params.listId) {
          let listToSelect
          let index = this.userCommunities.map(community => community.id).indexOf(this.$route.params.listId)
          if (index !== -1) {
            listToSelect = this.userCommunities[index]
          } else {
            index = this.userPersonalLists.map(personalList => personalList.id).indexOf(this.$route.params.listId)
            listToSelect = this.userPersonalLists[index]
          }
          this.$store.dispatch('contact/selectList', listToSelect)
        } else {
          this.$store.dispatch('contact/selectList', undefined) // necessary?
        }
      }
    )
  },
  methods: {
    changeMode (newMode) {
      this.mode = newMode
    },
    displayListOptions (event) {
      if (!this.isAContextMenuDisplayed && this.contextMenuOptions.length > 0 && this.selectedListIsPersonalList) {
        this.isContextMenuDisplayed = true
        this.$store.dispatch('contextMenu/openContextMenu',
          {
            event: event,
            options: this.contextMenuOptions
          })
      }
    },
    performChosenOption (option) {
      switch (option) {
        case 'delete':
          this.$store.dispatch('warningModal/addWarning', {
            text: this.$t('router.views.Contacts.Warning.deletePersonalList'),
            lastAction: { fct: this.deletePersonalList, params: [this.selectedList] }
          })
          break
        case 'modify':
          this.changeMode('update')
          break
        default:
          console.error('unknown action for option', option)
      }
      this.isContextMenuDisplayed = false
      this.$store.dispatch('contextMenu/closeMenus')
    },
    sendMessageToTheList () {
      // TODO: open sendingMessage modal
      console.log('TODO: open sendingMessage modal')
    },
    addMembers () {
      this.changeMode('update')
    },
    deletePersonalList (personalList) {
      const personalListToSelectAfter = this.$store.getters['contacts/previousList']
      const idPersonalListToSelectAfter = personalListToSelectAfter && !this.mq.phone && !this.mq.tablet ? personalListToSelectAfter.id : undefined
      contacts.deletePersonalList(personalList).then((data) => {
        if (data.success) {
          this.$store.dispatch('contact/getUserPersonalLists', { idPersonalListToSelect: idPersonalListToSelectAfter, mq: this.mq, router: this.$router })
        } else {
          console.error('Error while deleting community', data.error)
        }
      })
    },
    addPersonalList (personalList) {
      const newLength = this.userPersonalLists.push(personalList)
      this.selectedList = this.userPersonalLists[newLength - 1] // select the created personalList
    },
    updatePersonalList (updatedPersonalList) {
      for (let i = 0; i < this.userPersonalLists.length; ++i) {
        if (this.userPersonalLists[i].id === updatedPersonalList.id) {
          // remove deleted community from userCommunities
          this.userPersonalLists.splice(i, 1, updatedPersonalList)
          this.selectedList = this.userPersonalLists[i]
        }
      }
    },
    personalListDeleted (deletedPersonalList) {
      for (let i = 0; i < this.userPersonalLists.length; ++i) {
        if (this.userPersonalLists[i].id === deletedPersonalList.id) {
          // remove deleted community from userCommunities
          this.userPersonalLists.splice(i, 1)

          // update selected community
          if (this.selectedList.id === deletedPersonalList.id) {
            if (this.userPersonalLists.length === 0) {
              this.selectedList = undefined
            } else {
              if (i === this.userPersonalLists.length) {
                this.selectedList = this.userPersonalLists[this.userPersonalLists.length - 1]
              } else {
                this.selectedList = this.userPersonalLists[i]
              }
            }
          }
          break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.contacts{
  display: flex;
  height: calc(100% - #{$desktop-search-bar-height});

  .right-panel {
    flex: 3;
    display: flex;
    flex-direction: column;
  }

  &.phone {
    position: relative;
    height: calc(100% - #{$mobile-search-bar-height});

    .right-panel {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
    }
  }

  &.tablet {
    position: relative;
    height: calc(100% - #{$desktop-search-bar-height});

    .right-panel:not(.user-selection) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
    }

    .user-selection {
      position: relative;
      width: auto;
      height: auto;
      flex: 2;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
