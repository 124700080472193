<template>
  <div
    v-if="selectedList !== undefined"
    class="contact-display"
    :class="{'phone': mq.phone, 'tablet': mq.tablet}"
  >
    <div class="personal-list-data">
      <div class="personal-list-info">
        <div class="personal-list-info-header">
          <div class="thumbnail">
            <img
              src="@assets/icon_commu-black.svg"
              alt=""
              width="30"
            >
          </div>
          <div>
            <h2>{{ selectedList.name }}</h2>
            <div class="member-count">
              {{ selectedList.memberCount + ' ' + selectedListMemberLabel }}
            </div>
          </div>
          <div
            v-if="selectedListIsPersonalList"
            class="options"
            @click="displayPersonalListOptions"
          >
            <img
              src="@assets/icon_actions.svg"
              alt="options"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="personal-list-members-container">
      <div class="personal-list-members">
        <div class="header">
          <h3>{{ $t('router.views.Communities.members') + ' - ' + selectedList.memberCount }}</h3>
        </div>
        <div class="member-list">
          <CommunityMember
            v-for="(member, index) in selectedList.members"
            :key="index"
            class="member"
            :class="{'last': index === selectedList.members.length - 1}"
            :member="member"
          />
        </div>
      </div>
    </div>
    <Button
      v-if="mq.phone || mq.tablet"
      class="back-button"
      @click="closePersonalListDisplay"
    >
      <img
        src="@assets/icon_arrow_left.svg"
        alt=""
      >
      {{ $t('AppCommonsLabels.back') }}
    </Button>
  </div>
</template>

<script>
import CommunityMember from '@components/Communities/CommunityMember'
export default {
  name: 'ContactDisplay',
  components: { CommunityMember },
  inject: ['mq'],
  emits: ['displayPersonalListOptions'],
  computed: {
    selectedList () {
      return this.$store.state.contact.selectedList
    },
    selectedListIsPersonalList () {
      if (this.selectedList) {
        return this.$store.getters['contact/personalListIndex'](this.selectedList.id) !== -1
      } else {
        return false
      }
    },
    selectedListMemberLabel () {
      return this.selectedList.memberCount > 1 ? this.$t('router.views.Communities.members') : this.$t('router.views.Communities.member')
    }
  },
  methods: {
    closePersonalListDisplay () {
      this.$router.push({ name: 'contacts' })
    },
    displayPersonalListOptions (event) {
      this.$emit('displayPersonalListOptions', event)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.contact-display {

  .personal-list-data {
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    .background-picture-part {
      height: 175px;

      .background-picture {
        height: 100%;
        width: 100%;
      }
    }

    .personal-list-info {
      flex: 1;

      .personal-list-info-header {
        display: flex;
        align-items: center;

        .thumbnail {
          height: 70px;
          width: 70px;
          border-radius: 6px;
          background-color: #FFFFFF;
          box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 15px;
        }

        h2 {
          margin: 8px 10px;
        }

        .member-count {
          font-weight: bold;
          color: $public-bg;
          margin-left: 10px;
        }

        .options {
          margin-left: auto;
          margin-right: 5%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: 18px;
          background-color: $dark-white-bg;

          img {
            width: 18px;
            transform: rotate(90deg);
          }

          &:hover {
            color: $color-dark-text;
          }
        }
      }
    }
  }

  .personal-list-members-container {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 15px;

    overflow-y: auto;

    .personal-list-members {
      padding: 0 1rem;
      background-color: $dark-white-bg;

      .header {
        display: flex;
        align-items: center;
        font-weight: bold;
      }

      .member-list {
        padding-top: 10px;

        .member {
          margin-bottom: 15px;
          padding-bottom: 20px;
          border-bottom: 1px solid #D9E2EA;

          &.last {
            border-bottom: none;
          }
        }
      }
    }
  }

  &.phone {
    .personal-list-data {
      margin-bottom: 1rem;

      .background-picture-part{
        height: 81px;
      }

      .personal-list-info {
        margin-top: 60px;

        .personal-list-description {
          font-size: 1rem;
          margin-left: 15px;
        }
      }
    }

    .personal-list-members-container {
      padding: 0 15px;
    }

    .back-button {
      position: fixed;
      top: 20px;
      left: 20px;
      z-index: 2;
      font-size: 1rem;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      width: 105px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 10px
      }
    }
  }

  &.tablet {
    .personal-list-data {
      margin-bottom: 1rem;

      .personal-list-info {
        margin-top: 60px;

        .personal-list-description {
          font-size: 1rem;
          margin-left: 15px;
        }
      }
    }

    .personal-list-members-container {
      padding: 0 15px;
    }

    .back-button {
      position: fixed;
      top: 85px;
      left: 100px;
      z-index: 2;
      font-size: 1rem;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      width: 105px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 10px
      }
    }
  }
}

</style>
